import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  constructor(
      private localService: LocalStorageService
  ) { }

  public isAuthenticated (): boolean {
    const token = this.localService.getJsonValue('MiJWT');
    //console.log(' <<<< < Token', token);
    return token != null ? true : false;
  }
}

import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppMainComponent } from './app.main.component';

import { LandingComponent } from './pages/landing/landing.component';
import { AppLoginComponent } from './pages/login/app.login.component';
import { ErrorComponent } from './pages/error/error.component';
import { NotfoundComponent } from './pages/notfound/notfound.component';
import { AccessComponent } from './pages/access/access.component';
import { AuthorizationGuard } from './modules/seguridad/guards/authorization.guard';

@NgModule({
    imports: [
        RouterModule.forRoot([
            /* {
                path: '', component: AppMainComponent,
            }, */
            { path: 'template', component: AppMainComponent, loadChildren: () => import ('./modules/template/template.module').then (m => m.TemplateModule)  },
            { path: 'login', component: AppLoginComponent, loadChildren: () => import ('./modules/seguridad/seguridad.module').then (m => m.SeguridadModule) },
            { path: '', component: AppMainComponent, loadChildren: () => import ('./modules/reporteciudadano/reporteciudadano.module').then (m => m.ReporteciudadanoModule), canActivate: [AuthorizationGuard] },
            { path: 'pages/landing', component: LandingComponent },
            { path: 'pages/error', component: ErrorComponent },
            { path: 'pages/notfound', component: NotfoundComponent },
            { path: 'pages/access', component: AccessComponent },
            { path: '**', redirectTo: 'pages/notfound' },
        ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}

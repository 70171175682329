import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Message, MessageService, PrimeNGConfig } from 'primeng/api';
import { User } from './models/user';
import { AuthenticationService } from './modules/seguridad/services/authentication.service';
import { LocalStorageService } from './modules/seguridad/services/local-storage.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    providers: [MessageService]
})
export class AppComponent {

    usuario: User;

    menuMode = 'static';

    public loading = false;

    msgs: Message[] = [];

    constructor(
        private primengConfig: PrimeNGConfig,
        public messageService: MessageService,
        private localService: LocalStorageService,
        private auth: AuthenticationService,) { }

    ngOnInit() {
        this.primengConfig.ripple = true;
        document.documentElement.style.fontSize = '14px';
        this.getToken();
    }

    showMessage(tipo: string, titulo: string, mensaje: string) {
        this.msgs = [];
        this.msgs.push({ severity: tipo, summary: titulo, detail: mensaje });
        return this.msgs;
    }
    blockDocument() {
        this.loading = true;
        setTimeout(() => {
            this.loading = false;
        }, 0);
    }
    showLoading() {
        setTimeout(() => {
            this.loading = true;
        }, 0);
    }


    hideLoading() {
        setTimeout(() => {
            this.loading = false;
        }, 100);
    }

    showMensaje(tipo, titulo, cuerpo) {
        this.messageService.add({ severity: tipo, summary: titulo, detail: cuerpo });
    }

    setUsuario(data) {

        this.usuario = data;
    }
    getToken() {
        let token = this.localService.getJsonValue("MiJWT");
        if (token != null) {
            let tokenDecode = this.auth.decodeJWT(token);
            this.setUsuario(tokenDecode.data)
        }
    }

    handleError(response: HttpErrorResponse) {
        console.log("handleError", response);

        if (typeof response.error.err.name === 'string') {
            this.showMensaje('error', 'Error', response.error.err.name);
            this.loading = false;
        } else if (response.status === 0) {
            this.showMensaje('error', 'Error', 'Problema de conexión');
            this.loading = false;
        } else {
            if (response.error.messageResponse != undefined) {
                this.showMensaje('error', 'Error', response.error.messageResponse);
                this.loading = false;
            } else {
                this.showMensaje('error', 'Error', response.error.err);
                this.loading = false;
            }
        }

    }

}

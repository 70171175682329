<p-blockUI [blocked]="loading" [baseZIndex]="10000">
    <div>
        <div class="spinner">
            <span class="ball-1"></span>
            <span class="ball-2"></span>
            <span class="ball-3"></span>
            <span class="ball-4"></span>
            <span class="ball-5"></span>
            <span class="ball-6"></span>
            <span class="ball-7"></span>
            <span class="ball-8"></span>
        </div>
    </div>
</p-blockUI>
<p-toast [baseZIndex]="999998"></p-toast>
<router-outlet></router-outlet>
